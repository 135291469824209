@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

@import 'bulma/bulma';

.card {
  display:flex;
  flex-direction: column;
}
.card-footer {
  margin-top: auto;
}

.is-semi-transparent {
  background-color: rgba(255, 255, 255, 0.7);
}

#landing {
  background-image: url('/assets/img/landing.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 100vh;
}